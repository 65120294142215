import moment from 'moment'
import PdfViewer from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import pdfClient from "../../http-common.js";
import ePub from 'epubjs';
import dataServices from '../../services/data';

//SE DEFINEN ESTAS FUNCIONES DEL EPUB READER FUERA PARA EVITAR PROBLEMAS CON EL SCOPE
function nextPos(bookId, rendition) {
    rendition.next().then(() => {

        rendition.on('relocated', (location) => {
            console.log("start " + location.start.cfi);
            console.log("end " + location.end.cfi);
        });

        console.log("Rendition nextPos");
        console.log(rendition);
        //console.log("in promise next :" + " " + JSON.stringify(rendition.currentLocation()));

    });
    //console.log("next:" + rendition.currentLocation().end.cfi);
    dataServices.saveUserPositions(bookId, "epub", rendition.currentLocation().end.cfi, 0.0)

}

function prevPos(bookId, rendition) {
    rendition.prev();
    //console.log("prev:" + rendition.currentLocation().start.cfi);
    console.log("Rendition prevPos");
    console.log(rendition);
    dataServices.saveUserPositions(bookId, "epub", rendition.currentLocation().start.cfi, 0.0)
}

export default {
    name: 'Home',

    components: {
        PdfViewer
    },
    data() {
        return {
            userPositions: [],
            book_positions: [],
            currentLoc: undefined,
            ////////////////////////////////
            //PDF READER
            ////////////////////////////////
            config: {
                withCredentials: true,
                requestHeaders: {
                    'Access-Control-Allow-Origin': '*'
                },
                sidebar: false,
                secondaryToolbar: {
                    secondaryPresentationMode: true,
                    secondaryOpenFile: true,
                    secondaryPrint: true,
                    secondaryDownload: false,
                    secondaryViewBookmark: true,
                    firstPage: true,
                    lastPage: true,
                    pageRotateCw: true,
                    pageRotateCcw: true,
                    cursorSelectTool: true,
                    cursorHandTool: true,
                    scrollVertical: true,
                    scrollHorizontal: true,
                    scrollWrapped: true,
                    spreadNone: true,
                    spreadOdd: true,
                    spreadEven: true,
                    documentProperties: true,
                },
                toolbar: {
                    toolbarViewerLeft: {
                        findbar: true,
                        previous: true,
                        next: true,
                        pageNumber: true,
                    },
                    toolbarViewerRight: {
                        presentationMode: true,
                        openFile: false,
                        print: false,
                        download: false,
                        viewBookmark: true,
                    },
                    toolbarViewerMiddle: {
                        zoomOut: true,
                        zoomIn: true,
                        scaleSelectContainer: true,
                    },
                },
                errorWrapper: true,

            },
            name: '', //change which pdf file loads
            path: 'lib/pdfjs-2.8.335-dist/web/viewer.html',
            currentBookTitle: "",
            currentBookId: null,
            pdf: null,
            pdfReaderDialog: false,
            ////////////////////////////////
            //EPUB READER
            ////////////////////////////////
            epubReaderDialog: false
        }
    },
    beforeMount() {
        this.$store.dispatch('setSpinner', true);
        moment.locale("es");
    },
    async mounted() {
        try {
            await this.refreshMyBooksList();
            this.$store.dispatch('setSpinner', false);
        } catch (error) {
            console.log(error);
            // if (this.$store.getters.getUserInfo) {
            //     alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
            // }
            this.$store.dispatch('setSpinner', false);
        }
    },
    computed: {
        getPositions() {
            return this.book_positions;
        }
    },
    methods: {
        format_author(author){
            if(author.includes(',')){
                return author.split(", ").reverse().join(" ");
            } else {
                return author;
            }
        },
        goToBookList() {
            this.$router.push({
                name: "Finder",
                params: {}
            });
        },
        async openBookReader(item) {
            this.$store.dispatch('setSpinner', true);
            this.currentBookTitle = item.book_title;
            this.currentBookId = item.book_id;
            this.currentLoc = item.loc;
            if (item.book_url.endsWith(".pdf")) {
                this.pdf = await this.getFile(item.book_url);
                this.pdfReaderDialog = true;
            }
            if (item.book_url.endsWith(".epub")) {
                await this.loadEpubBook(item.book_url);
                this.epubReaderDialog = true;
            }
            this.$store.dispatch('setSpinner', false);
        },
        async closePdfReader(){
            try{
                await this.refreshMyBooksList();
                this.pdfReaderDialog = false;
            } catch(e){
                console.log(e);
                alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
                this.pdfReaderDialog = false
            }
        },
        async closeEpubReader(){
            try{
                await this.refreshMyBooksList();
                this.epubReaderDialog = false;
                let toc = document.getElementById("toc");
                toc.innerHTML = '';
                let viewer = document.getElementById("viewer");
                viewer.innerHTML = '';
            } catch(e){
                console.log(e);
                alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
                this.epubReaderDialog = false
            }
        },
        async refreshMyBooksList() {
            this.$store.dispatch('setSpinner', true);
            try {
                await this.$store.dispatch("fetchUserPositions");
                this.book_positions = [];
                this.userPositions = this.$store.getters.getUserPositions ? this.$store.getters.getUserPositions : this.userPositions;
                this.userPositions.map(
                    async position => {
                        const book_format = position.bookInfo.formats.filter(format => format.type.toUpperCase() === position.format.toUpperCase())
                        if (book_format.length)
                            this.book_positions.push({
                                id: position.id,
                                book_id: position.book,
                                book_title: position.bookInfo.title,
                                book_format: position.format,
                                loc: position.cfi,
                                book_url: book_format[0].url,
                                last_time: "Leído por última vez " + moment(new Date(position.epoch)).fromNow(),
                                author: this.format_author(position.bookInfo.author_sort),
                                book_cover: position.bookInfo.cover_min,
                                percentage: position.pos_frac
                            });
                    }
                );
                this.$store.dispatch('setSpinner', false);
            } catch (e) {
                console.log(e);
                alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
                this.$store.dispatch('setSpinner', false);
            }
        },
        async deleteBookFromList(book, url, event) {
            event.preventDefault();
            event.stopPropagation();
            let bookObj = {};
            if (url.endsWith(".pdf")) {
                bookObj.id = book;
                bookObj.ext = "pdf";
                await this.$store.dispatch("deleteBookFromList", bookObj);
                await this.refreshMyBooksList();
            } else if (url.endsWith(".epub")) {
                bookObj.id = book;
                bookObj.ext = "epub";
                await this.$store.dispatch("deleteBookFromList", bookObj);
                await this.refreshMyBooksList();
            }

        },
        capitalize(text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

            }
            const str = arr.join(" ");
            return str;
        },
        ////////////////////////////////////////////////////////////////
        //PDF READER
        ////////////////////////////////////////////////////////////////
        afterCreatedHandler(pdfApp) {
            this.pdfApp = pdfApp;
        },
        pagesRenderedHandler(pdfApp) {
            let percentage = pdfApp.page / pdfApp.pagesCount * 100;
            setTimeout(() => {
                dataServices.saveUserPositions(this.currentBookId, "pdf", pdfApp.page, percentage.toFixed(2));
            });
        },
        async openHandler(pdfApp) {
            pdfApp.eventBus.on('pagerendered', this.eventPageRendered);

            this.info = [];
            const info = await pdfApp.pdfDocument
                .getMetadata()
                .catch(console.error.bind(console));

            if (!info) return;
            const props = Object.keys(info.info);
            props.forEach((prop) => {
                const obj = {
                    name: prop,
                    value: info.info[prop]
                };
                this.info.push(obj);
            });

        },
        eventPageRendered() {
            let percentage = this.pdfApp.page / this.pdfApp.pagesCount * 100;
            dataServices.saveUserPositions(this.currentBookId, "pdf", this.pdfApp.page, percentage.toFixed(2));
        },
        async getFile(url) {
            return pdfClient.get(url, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }).then((response) => {
                return response.data;
            });
        },

        ////////////////////////////////////////////////////////////////
        //EPUB READER
        ////////////////////////////////////////////////////////////////
        async loadEpubBook(url) {
            this.$store.dispatch('setSpinner', true);
            try {
                // var params = URLSearchParams && new URLSearchParams(document.location.search.substring(1));
                var currentSectionIndex = this.currentLoc ? this.currentLoc : undefined;
                var currentBookId = this.currentBookId ? this.currentBookId : undefined;
                // Load the opf
                const bookOptions = {
                    openAs: 'epub',
                    requestHeaders: {
                        'Content-type': 'application/epub+zip',
                        'Access-Control-Allow-Origin': '*'
                    },
                    requestCredentials: true
                };
                var book = ePub(url, bookOptions);
                var rendition = book.renderTo("viewer", {
                    width: "100%",
                    height: "100%",
                    spread: "always"
                });

                rendition.display(currentSectionIndex)
                    .then(() => console.log("rendition displayed"));

                book.ready.then(() => {
                    console.log("book ready")
                    var next = document.getElementById("next");

                    next.addEventListener("click", function (e) {
                        book.package.metadata.direction === "rtl" ? prevPos(currentBookId, rendition) : nextPos(currentBookId, rendition);
                        e.preventDefault();
                    }, false);

                    var prev = document.getElementById("prev");
                    prev.addEventListener("click", function (e) {
                        book.package.metadata.direction === "rtl" ? nextPos(currentBookId, rendition) : prevPos(currentBookId, rendition);
                        e.preventDefault();
                    }, false);

                    var keyListener = function (e) {

                        // Left Key
                        if ((e.keyCode || e.which) == 37) {
                            book.package.metadata.direction === "rtl" ? nextPos(currentBookId, rendition) : prevPos(currentBookId, rendition);
                        }

                        // Right Key
                        if ((e.keyCode || e.which) == 39) {
                            book.package.metadata.direction === "rtl" ? prevPos(currentBookId, rendition) : nextPos(currentBookId, rendition);
                        }

                    };

                    rendition.on("keyup", keyListener);
                    document.addEventListener("keyup", keyListener, false);

                });

                //   var title = document.getElementById("title");
                rendition.on("relocated", function (location) {
                    console.log("relocated next:" + location.end.cfi);
                    console.log("relocated prev:" + location.start.cfi);
                    console.log("relocated location:" + JSON.stringify(rendition.currentLocation()));

                    const progress = book.locations.percentageFromCfi(location.start.cfi);
                    console.log('Progress:', progress); // The % of how far along in the book you are
                    //  console.log('Current Page:', book.locations.locationFromCfi(locations.start.cfi));
                    //  console.log('Total Pages:', book.locations.total);

                    var next = book.package.metadata.direction === "rtl" ? document.getElementById("prev") : document.getElementById("next");
                    var prev = book.package.metadata.direction === "rtl" ? document.getElementById("next") : document.getElementById("prev");

                    if (location.atEnd) {
                        next.style.visibility = "hidden";
                    } else {
                        next.style.visibility = "visible";
                    }

                    if (location.atStart) {
                        prev.style.visibility = "hidden";
                    } else {
                        prev.style.visibility = "visible";
                    }

                });

                rendition.on("rendered", function (section) {
                    var current = book.navigation && book.navigation.get(section.href);

                    if (current) {
                        var $select = document.getElementById("toc");
                        var $selected = $select.querySelector("option[selected]");
                        if ($selected) {
                            $selected.removeAttribute("selected");
                        }

                        var $options = $select.querySelectorAll("option");
                        for (var i = 0; i < $options.length; ++i) {
                            let selected = $options[i].getAttribute("ref") === current.href;
                            if (selected) {
                                $options[i].setAttribute("selected", "");
                            }
                        }
                    }

                });

                rendition.on("layout", function (layout) {
                    let viewer = document.getElementById("viewer");

                    if (layout.spread) {
                        viewer.classList.remove('single');
                    } else {
                        viewer.classList.add('single');
                    }
                });

                window.addEventListener("unload", function () {
                    console.log("unloading");
                    this.book.destroy();
                });

                book.loaded.navigation.then(function (toc) {
                    var $select = document.getElementById("toc"),
                        docfrag = document.createDocumentFragment();

                    toc.forEach(function (chapter) {
                        var option = document.createElement("option");
                        option.textContent = chapter.label;
                        option.setAttribute("ref", chapter.href);

                        docfrag.appendChild(option);
                    });

                    $select.appendChild(docfrag);

                    $select.onchange = function () {
                        var index = $select.selectedIndex,
                            url = $select.options[index].getAttribute("ref");
                        rendition.display(url);
                        return false;
                    };

                });
                this.$store.dispatch('setSpinner', false);
            } catch (error) {
                console.log(error);
                alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.");
                this.$store.dispatch('setSpinner', false);
            }
        }
    }
}